import Img1 from "../../assets/img/BeninRepublicCrusade2024/benin1.webp";
import Img2 from "../../assets/img/BeninRepublicCrusade2024/benin2.webp";
import Img3 from "../../assets/img/BeninRepublicCrusade2024/benin3.webp";
import Img4 from "../../assets/img/BeninRepublicCrusade2024/benin4.webp";

import { useState } from "react";
import { Link } from "react-router-dom";
const BeninRepublicGallery = () => {
  const imgData = [
    {
      id: 1,
      img: Img1,
    },
    {
      id: 2,
      img: Img2,
    },
    {
      id: 3,
      img: Img3,
    },
    {
      id: 4,
      img: Img4,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openFullScreen = (index: any) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };
  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black sm:text-3xl text-[26px] font-RalewayBold">
          Benin Republic Crusade 2024
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 sm:w-[200px] w-[150px] rounded-full bg-black"></div>
        </div>
        <div className="text-start sm:mt-8 mt-5">
          <p className="font-RalewayMedium sm:text-[16px] text-[13px]">
            About 100 souls saved More than 20 filled with the Holy Ghost with
            the evidence of speaking in tongues. Several healings and miracles.
            Over 15,000 people reached with the gospel of Jesus Christ via
            national television stations.{" "}
            <span className="font-OpenSansItalic">
              Thank you for partnering with us. God bless you
            </span>
          </p>
        </div>
        <div>
          <h1 className="text-[20px] mt-7 mb-3 font-RalewayBold">
            Videos of the Crusade
          </h1>
          <p className="font-OpenSansItalic text-[12px]">
            Please click on the links below to watch the video.
          </p>
          <div className="mt-5">
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/T_PR4fnomCw?si=94rlq91XyAiMjIeL"
              >
                Republic of Benin Pastors and Leaders Conference video 1
              </Link>
            </div>
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/HCoNusvDGdY?si=Mo3gWzWkoXHFIC7g"
              >
                Republic of Benin Pastors and Leaders Conference video 2
              </Link>
            </div>
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/Toc7xm9EchU?si=877nMucLkakVoRUy"
              >
                Republic of Benin Pastors and Leaders Conference video 3
              </Link>
            </div>
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/1qpXIHpYdxE?si=wxtuFrvodCxPjFHa"
              >
                A woman paralyzed from waist down healed
              </Link>
            </div>
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/Z7WdBWXq20M?si=5IK08o4IF4gVStAwA
"
              >
                Intense stomach pain healed
              </Link>
            </div>
            <div className="mb-3">
              <Link
                target="blank"
                className="hover:text-[#FDB53C] underline block"
                to="https://youtu.be/1y7CuPbfpCE?si=pmvIBSmiSxYdfB1c
"
              >
                Heavy Spiritual weight lifted
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-[60px]">
        {imgData.map((item, index) => (
          <div
            key={index}
            onClick={() => openFullScreen(index)}
            className="pics"
          >
            <img src={item.img} className="w-full" alt="img" />
          </div>
        ))}
        {selectedIndex !== null && (
          <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
            <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
              <img
                src={imgData[selectedIndex].img}
                alt={`Full Screen ${selectedIndex}`}
                className="object-contain h-full w-full"
                onClick={closeFullScreen}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BeninRepublicGallery;
