import Img1 from "../../assets/img/Ghana Crusade 2024/ghana1.webp";
import Img2 from "../../assets/img/Ghana Crusade 2024/ghana2.webp";
import Img3 from "../../assets/img/Ghana Crusade 2024/ghana3.webp";
import Img4 from "../../assets/img/Ghana Crusade 2024/ghana4.webp";
import Img5 from "../../assets/img/Ghana Crusade 2024/ghana5.webp";
import Img6 from "../../assets/img/Ghana Crusade 2024/ghana6.webp";
import Img7 from "../../assets/img/Ghana Crusade 2024/ghana7.webp";
import Img8 from "../../assets/img/Ghana Crusade 2024/ghana8.webp";
import Img9 from "../../assets/img/Ghana Crusade 2024/ghana9.webp";
import Img10 from "../../assets/img/Ghana Crusade 2024/ghana10.webp";
import Img11 from "../../assets/img/Ghana Crusade 2024/ghana11.webp";
import Img12 from "../../assets/img/Ghana Crusade 2024/ghana12.webp";
import Img13 from "../../assets/img/Ghana Crusade 2024/ghana13.webp";
import Img14 from "../../assets/img/Ghana Crusade 2024/ghana14.webp";
import Img15 from "../../assets/img/Ghana Crusade 2024/ghana15.webp";
import Img16 from "../../assets/img/Ghana Crusade 2024/ghana16.webp";
import ImgI from "../../assets/img/Ghana Crusade 2024/ghanaImg.webp";
import ImgII from "../../assets/img/Ghana Crusade 2024/ghanaImg2.webp";
import Video1 from "../../assets/img/Ghana Crusade 2024/ghanaVid.mp4";
import Video2 from "../../assets/img/Ghana Crusade 2024/GhanaVid2.mp4";
import { useEffect, useRef, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
const GhanaGallery = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const imgData = [
    {
      id: 1,
      img: Img1,
    },
    {
      id: 2,
      img: Img2,
    },
    {
      id: 3,
      img: Img3,
    },
    {
      id: 4,
      img: Img4,
    },
    {
      id: 5,
      img: Img5,
    },
    {
      id: 6,
      img: Img6,
    },
    {
      id: 7,
      img: Img7,
    },
    {
      id: 8,
      img: Img8,
    },
    {
      id: 9,
      img: Img9,
    },
    {
      id: 10,
      img: Img10,
    },
    {
      id: 11,
      img: Img11,
    },
    {
      id: 12,
      img: Img12,
    },
    {
      id: 13,
      img: Img13,
    },
    {
      id: 14,
      img: Img14,
    },
    {
      id: 15,
      img: Img15,
    },
    {
      id: 16,
      img: Img16,
    },
  ];
  const videos = [
    {
      title: "GHANA CRUSADE VIDEO I",
      src: Video1,
      image: ImgI,
    },
    {
      title: "GHANA CRUSADE VIDEO II",
      src: Video2,
      image: ImgII,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openFullScreen = (index: any) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };
  const openModal = (src: string, title: string) => {
    setVideoSrc(src);
    setVideoTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoSrc("");
    setVideoTitle("");
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (isModalOpen && videoSrc && videoElement) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
  }, [isModalOpen, videoSrc]);
  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black sm:text-3xl text-[26px] font-RalewayBold">
          Ghana Crusade 2024 Gallery
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 sm:w-[200px] w-[150px] rounded-full bg-black"></div>
        </div>
        <div className="mt-6">
          <h1 className=" text-[14px]">
            As it is with all the places God has taken us to, Ghana Crusade
            wasn't different as we witnessed great testimonies of salvation,
            healing and deliverance.
          </h1>
        </div>
      </div>
      <div className="mb-[60px] px-[3%]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
          {imgData.map((item, index) => (
            <div
              key={index}
              onClick={() => openFullScreen(index)}
              className="pics"
            >
              <img src={item.img} className="w-full" alt="img" />
            </div>
          ))}
          {selectedIndex !== null && (
            <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
              <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
                <img
                  src={imgData[selectedIndex].img}
                  alt={`Full Screen ${selectedIndex}`}
                  className="object-contain h-full w-full"
                  onClick={closeFullScreen}
                />
              </div>
            </div>
          )}
          {videos.map((video, index) => (
            <div key={index} className="relative">
              <img
                src={video.image}
                alt={video.title}
                className="object-cover w-full h-full rounded"
              />
              <button
                onClick={() => openModal(video.src, video.title)}
                className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 play-button"
              >
                <AiFillPlayCircle className="text-[#FDB53C] text-6xl play-icon" />
              </button>
            </div>
          ))}
          {isModalOpen && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
              aria-labelledby="exampleModalLabel"
              aria-hidden={!isModalOpen}
            >
              <div className="relative w-full max-w-lg border border-white mx-auto bg-white rounded">
                <div className="modal-header p-4 border-b flex items-center justify-between border-gray-200">
                  <h5
                    className="text-xl font-NotoSansBold"
                    id="exampleModalLabel"
                  >
                    {videoTitle}
                  </h5>
                  <button
                    type="button"
                    className="text-black bg-transparent border-none text-3xl leading-none"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body p-4 bg-black">
                  <div className="relative" style={{ paddingBottom: "56.25%" }}>
                    <video
                      className="absolute top-0 left-0 w-full h-full"
                      ref={videoRef}
                      controls
                      controlsList="nodownload"
                      src={videoSrc}
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GhanaGallery;
