import { AiFillPlayCircle } from "react-icons/ai";
import Img1 from "../../assets/img/MissionsToKenya2023/kenya.webp";
import Img2 from "../../assets/img/MissionsToKenya2023/kenya1.webp";
import Img3 from "../../assets/img/MissionsToKenya2023/kenya2.webp";
import Img4 from "../../assets/img/MissionsToKenya2023/kenya3.webp";
import Img5 from "../../assets/img/MissionsToKenya2023/kenya4.webp";
import Img6 from "../../assets/img/MissionsToKenya2023/kenya5.webp";
import Img7 from "../../assets/img/MissionsToKenya2023/kenya6.webp";
import Img8 from "../../assets/img/MissionsToKenya2023/kenya7.webp";
import Img9 from "../../assets/img/MissionsToKenya2023/kenya8.webp";
import Video from "../../assets/img/MissionsToKenya2023/kenyavid.mp4";
import Img from "../../assets/img/MissionsToKenya2023/kenyapic.webp";
import { useEffect, useRef, useState } from "react";
const KenyaGallery = () => {
  const imgData = [
    {
      id: 1,
      img: Img1,
    },
    {
      id: 2,
      img: Img2,
    },
    {
      id: 3,
      img: Img3,
    },
    {
      id: 4,
      img: Img4,
    },
    {
      id: 5,
      img: Img5,
    },
    {
      id: 6,
      img: Img6,
    },
    {
      id: 7,
      img: Img7,
    },
    {
      id: 8,
      img: Img8,
    },
    {
      id: 9,
      img: Img9,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openFullScreen = (index: any) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const videos = [
    {
      title: "RAIN OUTREACH",
      src: Video,
      image: Img,
      width: 640,
      height: 360,
    },
  ];

  const openModal = (src: string, title: string) => {
    setVideoSrc(src);
    setVideoTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoSrc("");
    setVideoTitle("");
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isModalOpen && videoSrc && videoElement) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
  }, [isModalOpen, videoSrc]);
  return (
    <>
      <div className="px-[3%] pt-10 pb-[60px] text-center">
        <h2 className="text-black text-3xl font-RalewayBold">
          Missions To Kenya 2023 Gallery
        </h2>
        <div className="flex justify-center pt-4">
          <div className="h-2 w-[200px] rounded-full bg-black"></div>
        </div>
        <div className="mt-6">
          <h1 className=" text-[14px]">
            In 2023, we took the gospel to Kenya and as always God's power was
            evident, souls saved and amazing miracles happened. God showed
            Himself mightily in Kenya as sister Ese Beloved ministered God's
            word in power.
          </h1>
        </div>
      </div>
      <div className="mb-[60px] px-[3%]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
          {imgData.map((item, index) => (
            <div
              key={index}
              onClick={() => openFullScreen(index)}
              className="pics"
            >
              <img src={item.img} className="w-full" alt="img" />
            </div>
          ))}
          {selectedIndex !== null && (
            <div className="fixed top-0 left-0 z-50 w-full h-full bg-black flex justify-center items-center">
              <div className="max-w-screen-lg w-full h-full flex justify-center items-center">
                <img
                  src={imgData[selectedIndex].img}
                  alt={`Full Screen ${selectedIndex}`}
                  className="object-contain h-full w-full"
                  onClick={closeFullScreen}
                />
              </div>
            </div>
          )}
          {videos.map((video, index) => (
            <div key={index} className="relative w-full">
              <img
                src={video.image}
                width={video.width}
                height={video.height}
                alt={video.title}
                className="object-cover w-full h-full rounded"
              />
              <button
                onClick={() => openModal(video.src, video.title)}
                className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 play-button"
              >
                <AiFillPlayCircle className="text-[#FDB53C] xxs:text-6xl text-[40px] play-icon" />
              </button>
            </div>
          ))}

          {isModalOpen && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
              aria-labelledby="exampleModalLabel"
              aria-hidden={!isModalOpen}
            >
              <div className="relative w-full max-w-lg border border-white mx-auto bg-white rounded">
                <div className="modal-header p-4 border-b flex items-center justify-between border-gray-200">
                  <h5
                    className="text-xl font-NotoSansBold"
                    id="exampleModalLabel"
                  >
                    {videoTitle}
                  </h5>
                  <button
                    type="button"
                    className="text-black bg-transparent border-none text-3xl leading-none"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body p-4 bg-black">
                  <div className="relative" style={{ paddingBottom: "56.25%" }}>
                    <video
                      className="absolute top-0 left-0 w-full h-full"
                      ref={videoRef}
                      controls
                      controlsList="nodownload"
                      src={videoSrc}
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KenyaGallery;
