import React from "react";
import { AiFillPlayCircle } from "react-icons/ai";

interface VideoCardProps {
  title: string;
  src: string;
  image: string;
  onClick: (src: string, title: string) => void;
}

const VideoCard: React.FC<VideoCardProps> = ({
  title,
  src,
  image,
  onClick,
}) => {
  return (
    <div className="relative overflow-hidden shadow-lg bg-white">
      <img
        src={image}
        width={640}
        height={360}
        alt={title}
        className="object-cover w-full h-full"
      />
      <button
        onClick={() => onClick(src, title)}
        className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 play-button"
      >
        <AiFillPlayCircle className="text-[#FDB53C] text-6xl play-icon" />
      </button>
    </div>
  );
};

export default VideoCard;
